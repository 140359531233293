import Vue from 'vue';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;
Vue.prototype.$baseUrl = 'https://webgongyi.hsagy.com';
Vue.prototype.$webUrl = 'https://hsa.mozicishan.com';

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app');
