<template>
  <div class="listTitle">
    <i class="icon"></i>
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  }
};
</script>

<style lang="scss" scope>
.listTitle {
  font-size: 16px;
  height: 40px !important;
  display: flex;
  align-items: center;
  color: #333;
  background: #fff;
  margin-top: 10px;
  height: 0.3rem;
  font-weight: bold;
  box-sizing: border-box;
  margin-top: 20px;
}
.icon {
  display: inline-block;
  width: 2px;
  height: 8px;
  background: url('@/image/home/icon1.png') no-repeat;
  background-size: 100% 100%;
  margin-right: 4px;
}
</style>
