import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/homePage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/loveShow',
    name: 'loveShow',
    component: () => import('@/views/loveShow')
  },
  {
    path: '/detail/:id',
    name: 'cardDetail',
    component: () => import('@/views/cardDetail')
  },
  {
    path: '/me',
    name: 'me',
    component: () => import('@/views/mePage')
  },
  {
    path: '/me_donate',
    name: 'me_donate',
    component: () => import('@/views/meDonate')
  },
  {
    path: '/personal_data',
    name: 'personal_data',
    component: () => import('@/views/personalData')
  },
  {
    path: '/share_friend',
    name: 'share_friend',
    component: () => import('@/views/shareFriend')
  },
  {
    path: '/certificate',
    name: 'certificate',
    component: () => import('@/views/myCertificate')
  },
  {
    path: '/pay_ali',
    name: 'pay_ali',
    component: () => import('@/views/aliPay')
  },
  {
    path: '/pay_wx',
    name: 'pay_wx',
    component: () => import('@/views/wxPay')
  },
  {
    path: '/loading',
    name: 'loading',
    component: () => import('@/views/payLoading')
  },
  {
    path: '/payHome',
    name: 'payHome',
    component: () => import('@/views/payHome')
  }

  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () =>
  //   import(/* webpackChunkName: "about" */ '../views/homePage.vue')
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
