import { request } from '@/util/request';

export const getHomeListApi = async (params) => {
  return request.get(`/index?uid=${params.id}&page=${params.loadPage}`);
};

export const getUserInfoApi = async (type, data, params) => {
  return request.post(
    `/user?type=${type}&data=${JSON.stringify(data)}`,
    params
  );
};

export const getDetailApi = async (params) => {
  return request.post('/details', params);
};

export const getConLogApi = async (params) => {
  return request.post('/con_log', params);
};

export const getCertificateApi = async (params) => {
  return request.post('/certificate', params);
};

// 头像上传
export const setUploadPictureApi = async (params) => {
  return request.post('/uploadPicture', params);
};

export const setReNewApi = async (params) => {
  return request.post('/renew', params);
};

// 支付
export const onPay = async (params) => {
  return request.post('/pay', params);
};

// 订单查询
export const onInquireOrder = async (params) => {
  return request.post('/is_pay', params);
};
