<template>
  <div class="page_tabbar">
    <van-tabbar
      v-model="active"
      route
      :fixed="true"
      @change="onChange"
      active-color="#FF554B"
      inactive-color="#B39B9B"
    >
      <van-tabbar-item to="/home">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.activeHomeIcon : icon.homeIcon" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/loveShow">
        <span>爱心秀</span>
        <template #icon="props">
          <img :src="props.active ? icon.activeLoveIcon : icon.loveIcon" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/me">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon.activeMeIcon : icon.meIcon" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant';
export default {
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  data() {
    return {
      active: 0,
      icon: {
        homeIcon: require('@/image/home/home.png'),
        activeHomeIcon: require('@/image/home/active_home.png'),
        loveIcon: require('@/image/home/love.png'),
        activeLoveIcon: require('@/image/home/active_love.png'),
        meIcon: require('@/image/home/me.png'),
        activeMeIcon: require('@/image/home/active_me.png')
      }
    };
  },
  methods: {
    onChange(index) {
      this.active = index;
    }
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 600px) {
  // pc端适配
  .page_tabbar {
    margin: 0 auto;

    :deep(.van-tabbar) {
      width: 375px !important;
    }
    :deep(.van-tabbar--fixed) {
      position: fixed;
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
}
.page_tabbar {
  width: 100%;
}
</style>
