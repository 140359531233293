import axios from 'axios';
export const request = axios.create({
  // 设置请求地址前缀
  baseURL: 'https://webgongyi.hsagy.com',
  // 请求超时时间
  timeout: 60000
  // 自定义请求头
  // headers: {}
});

/**
 * @name 请求拦截
 * @param {Object} config 请求数据
 * @returns {*}
 */
const handleRequestFunc = (config) => {
  return config;
};

/**
 * @name 响应拦截
 * @param {Obejct} res 响应参数
 * @returns {*}
 */
const handleResponseFunc = (res) => {
  // console.log(res, '响应拦截')
  let result = JSON.parse(res.data);
  return result;
};

/**
 * @name 接口异常
 * @param {Object} error 失败结果
 * @returns {*}
 */
const handleInterFaceError = (error) => {
  console.log('error', error.code);
  return error;
};

// 请求拦截器
request.interceptors.request.use(handleRequestFunc, handleInterFaceError);
// 响应拦截器
request.interceptors.response.use(handleResponseFunc, handleInterFaceError);

// // 将token信息添加到请求头中
// let token = sessionStorage.getItem('token');
// instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

// export const setToken = () => {
//   let token = sessionStorage.getItem('token');
//   if (token && token !== '') {
//     instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//   }
// };
