<template>
  <div class="card_list">
    <van-list
      class="list_box"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      :offset="10"
      @load="onLoad"
    >
      <div
        class="list_item"
        v-for="(item, index) in listData"
        :key="index"
        @click="goDetail(item.id)"
      >
        <div class="item_pic">
          <i class="help_icon"></i>
          <img :src="item.img" />
        </div>
        <div class="detail_box">
          <div class="text_box">
            <p>{{ item.title }}</p>
            <p>{{ item.intro }}</p>
          </div>
          <div class="love_info">
            <div class="info_item">
              <span>{{ item.target }}</span>
              <span>目标金额</span>
            </div>
            <div class="info_item">
              <span>{{ item.raised }}</span>
              <span>筹款金额</span>
            </div>
            <div class="info_item">
              <span>{{ item.num }}</span>
              <span>捐款人次</span>
            </div>
          </div>
          <div class="progress">
            <van-progress
              :percentage="item.schedule"
              stroke-width="8"
              color="rgba(255, 64, 53, 1)"
            />
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { List } from 'vant';
import { Progress } from 'vant';
import { getHomeListApi } from '@/api/home';
export default {
  props: {
    id: Number
  },
  components: {
    [List.name]: List,
    [Progress.name]: Progress
  },
  data() {
    return {
      loading: false,
      finished: false,
      listData: [],
      loadPage: 0,
      list: []
    };
  },
  mounted() {
    // this.getHomeList();
    // this.listData = this.list;
    this.onLoad();
  },

  methods: {
    // async getHomeList() {},
    goDetail(id) {
      this.$router.push({
        path: `/detail/${id}?source=home`
      });
    },
    async onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loadPage++;
      this.loading = true;

      let userId = JSON.parse(localStorage.getItem('userInfo')).id || '';
      // let userId = '1';
      let parmas = {
        id: userId,
        loadPage: this.loadPage
      };
      let r = await getHomeListApi(parmas);
      // this.list.forEach((item) => {
      //   let imgUrl = item.img.split(',');
      //   let url = imgUrl[0];
      //   item.img = url;
      //   // item.img = 'http://gy.schlhj.com' + imgUrl[0];
      //   // console.log(item.img);
      // });

      if (r.data.con_list.length == 0) {
        this.finished = true;
      }
      r.data.con_list.forEach((item) => {
        let imgUrl = item.img.split(',');
        let url = imgUrl[0];
        item.img = url;
      });

      this.listData = this.listData.concat(r.data.con_list);
      // 加载状态结束
      this.loading = false;

      // this.listData = r.data.con_list;
      // this.listData.push({
      //   img: require('../image/home/d.jpg'),
      //   title: '这个地方是标题蛴孙庄蒜插个蛀锂脸壶茨枢勉桩...',
      //   subTitle:
      //     '这里是描述蚁吸犹召。喀，饲猫二颂筒侮窝车刚倡鳍锯七小霍新盖鬃副序杆领伞委允肚服拧零钙洲途养镶妹仕砚发发...'
      // });

      // 数据全部加载完成
    }
  }
};
</script>

<style lang="scss" scoped>
.card_list {
  font-size: 14px;
  box-sizing: border-box;
}

.list_item {
  box-shadow: 0px 0px 10px 1px rgba(75, 4, 0, 0.05);
  margin-top: 0.1rem;
  border-radius: 0.04rem;
  .item_pic {
    position: relative;
    .help_icon {
      display: inline-block;
      width: 0.6rem;
      height: 0.24rem;
      background: url('@/image/home/help.png') no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
    img {
      width: 100%;
      height: 1.94rem;
      background: no-repeat;
      background-size: 100% 100%;
      border-radius: 4px;
    }
  }
  .detail_box {
    padding: 0 0.15rem;
  }
  .text_box {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    & :first-child {
      color: #613939;
      font-size: 14px;
      font-weight: bold;
      width: 100%;
      overflow: hidden;
      //显示省略符号来代表被修剪的文本
      text-overflow: ellipsis;
      //文本不会换行，文本会在在同一行上继续，直到遇到 <br> 标签为止。
      white-space: nowrap;
      margin-top: 0.1rem;
    }
    & :last-child {
      color: #998a8a;
      font-size: 12px;
      font-weight: 400;
      width: 100%;
      height: 32px;
      overflow: hidden; //超出盒子隐藏
      text-overflow: ellipsis; //文本溢出时，显示省略标记（.......）。
      display: -webkit-box; //将对象作为弹性伸缩盒子显示。
      -webkit-box-orient: vertical; //从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）。
      -webkit-line-clamp: 2; //这个属性不是css的规范属性，需要组合上上面两个属性，表示显示的行数。
      margin-bottom: 0.1rem;
    }
  }

  .love_info {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #f0f0f0;
    padding-top: 0.1rem;

    .info_item {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & :first-child {
        color: #333333;
        font-size: 14px;
        font-weight: bold;
      }
      & :last-child {
        color: #998a8a;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .progress {
    // margin: 0.15rem 0 0.2rem 0;
    height: 35px;
    margin-top: 15px;
  }
}
</style>
